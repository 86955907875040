
.bullet-chat {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 20px 0;
    .title-box {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-left: 20px;
        font-size: 18px;
        .decorate {
            width: 31px;
            height: 11px;
            margin-right: 10px;
            vertical-align: middle;
        }
    }
    .content {
        flex: 1;
        overflow: hidden;
        .list {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .container {
                box-sizing: border-box;
                width: 75%;
                overflow: hidden;
            }
            .item {
                box-sizing: border-box;
                display: flex;
                width: fit-content;
                padding-right: 10px;
                margin-bottom: 15px;
                font-size: 16px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.3);
                border-radius: 15px;
                .avatar-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    margin-right: 9px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: #fff;
                    vertical-align: middle;
                    .avatar-img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        vertical-align: middle;
                    }
                }
                .desc {
                    flex: 1;
                    overflow: hidden;
                    line-height: 27px;
                    word-break: break-all;
                    .username {
                        color: #4b45ff;
                    }
                }
            }
        }
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
