
.not-start-script {
    position: relative;
    .line {
        position: absolute;
        top: 0;
        left: 15%;
        bottom: 20px;
        width: 1px;
        background-color: #444797;
    }
    .total-list {
        .total-item {
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;
            .title-box {
                position: relative;
                display: flex;
                align-items: center;
                align-self: flex-end;
                width: 85%;
                margin-bottom: 15px;
                font-size: 18px;
                transform: translateX(-13px);
                .sequential-num {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 50%;
                    background-color: #bfbfbf;
                }
            }
            .content {
                display: flex;
                width: 100%;
                .script-snippet-box {
                    width: 100%;
                    box-sizing: border-box;
                    opacity: 0.6;
                    line-height: 30px;
                    overflow: hidden;
                    .script-snippet-item {
                        display: flex;
                        width: 100%;
                        overflow: hidden;
                        .time {
                            box-sizing: border-box;
                            width: 15%;
                            padding-right: 15px;
                            text-align: end;
                            overflow: hidden;
                        }
                        .text {
                            box-sizing: border-box;
                            width: 85%;
                            padding: 0 30px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}
