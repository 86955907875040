
.goods {
    .container {
        .content {
            display: flex;
            flex-direction: column;
            .goods-item {
                box-sizing: border-box;
                display: flex;
                padding-right: 20px;
                .left-serial {
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 55px;
                    padding: 20px 0;
                    font-family: 缁忓吀缁艰壓浣撶畝;
                    font-size: 18px;
                    color: #1b162a;
                }
                .right-goods-info {
                    display: flex;
                    flex: 1;
                    padding: 20px 0 20px;
                    border-bottom: 1px solid #ddd;
                    overflow: hidden;
                    .goods-img-box {
                        position: relative;
                        width: 115px;
                        height: 115px;
                        vertical-align: middle;
                        .goods-img {
                            width: 100%;
                            height: 100%;
                        }
                        .state-mask-box {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            top: 0;
                            right: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: rgba(0, 0, 0, 0.3);
                            .state {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 80px;
                                height: 30px;
                                color: #fff;
                                font-size: 14px;
                                border-radius: 4px;
                                background-color: #fb0f4b;
                                .icon {
                                    margin-right: 6px;
                                    font-size: 14px;
                                }
                            }
                            .state-end {
                                background-color: #a0a0a0;
                            }
                        }
                    }
                    .goods-info {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin: 0 10px 0 20px;
                        overflow: hidden;
                        .top-info {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            overflow: hidden;
                            .title {
                                font-size: 18px;
                                color: #1b162a;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            .coupon-info {
                                display: flex;
                                flex-wrap: nowrap;
                                width: 100%;
                                margin-top: 10px;
                                .coupon-item {
                                    margin-right: 10px;
                                    padding: 2px 9px;
                                    font-size: 14px;
                                    color: #fe3266;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    border: 1px solid #fe3266;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    .decorate {
                                        margin-right: 9px;
                                    }
                                }
                                .coupon-item:nth-child(2n) {
                                    margin-right: 0;
                                }
                            }
                        }
                        .bottom-price {
                            font-size: 24px;
                            color: #cb0505;
                            .decorate {
                                font-size: 16px;
                            }
                        }
                    }
                    .action {
                        display: flex;
                        align-items: flex-end;
                        .item {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 90px;
                            height: 36px;
                            color: #fff;
                            background-color: #4b45ff;
                        }
                        .lose {
                            background-color: #12c1c1;
                        }
                        .finish {
                            background-color: #aaa;
                            cursor: default;
                        }
                    }
                }
            }
        }
    }
}
