
.coupon {
    .container {
        .content {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 16px 18px;
            .coupon-item {
                display: flex;
                width: 100%;
                height: 133px;
                margin-bottom: 8px;
                border: 1px solid #fe3266;
                border-left-style: dashed;
                /* border-right: 0; */
                overflow: hidden;
                .left-coupon-size {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 130px;
                    padding: 20px 0;
                    color: #fe3266;
                    font-size: 48px;
                    text-align: center;
                    .discount .decorate {
                        margin-left: 5px;
                    }
                    .decorate {
                        font-size: 16px;
                        font-weight: 700;
                    }
                    .full-reduction {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .decorate {
                            font-size: 18px;
                        }
                    }
                    .amount {
                        font-weight: 700;
                        font-size: 14px;
                    }
                }
                .center-info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 23px 10px 23px 0;
                    .title-box {
                        display: flex;
                        .decorate {
                            padding: 4px;
                            margin-right: 10px;
                            font-size: 14px;
                            color: #fe3266;
                            font-weight: 700;
                            background-color: #f9d6df;
                            border-radius: 4px;
                        }
                        .title {
                            flex: 1;
                            font-size: 18px;
                            color: #1b162a;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-weight: 700;
                        }
                    }
                    .desc-info {
                        font-size: 14px;
                        color: #5c5c6f;
                        font-weight: 700;
                        & .info-item:last-child {
                            margin-top: 5px;
                        }
                    }
                }
                .right-action {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 138px;
                    height: 100%;
                    font-size: 27px;
                    color: #fff;
                    background-image: linear-gradient(179deg, #ff7073, #f63849);
                    cursor: pointer;
                    .mask {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        background-color: transparent;
                        transition: all 0.3s;
                    }
                    &:hover .mask {
                        background-color: rgba(255, 255, 255, 0.3);
                    }
                    .wavy-line {
                        position: absolute;
                        left: -7.5px;
                        .circle-item {
                            width: 15px;
                            height: 15px;
                            margin-top: 5px;
                            border-radius: 50%;
                            background-color: #fff;
                        }
                        .circle-item:first-child {
                            margin-top: 0;
                        }
                    }
                    .row-item {
                        text-align: center;
                        letter-spacing: 5px;
                        transform: translateX(2.5px);
                    }
                }
                .added {
                    background-image: linear-gradient(160deg, #ddd, #aaa);
                    cursor: default;
                }
            }
        }
    }
}
