
.gift-box {
  box-sizing: border-box;
  width: 100%;
  .grid-box {
    box-sizing: border-box;
    padding: 30px;
    display: grid;
    grid-template-rows: 132px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    .gift-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    .gift-active {
      border: 1px solid #ff2c56;
      border-radius: 6px;
    }
  }
  .btn-box {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    border-top: 2px solid #ff2c56;
    margin-top: 188px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 30px;
    position: relative;
    font-size: 18px;
    color: #fff;
    line-height: 33px;
    .btn-gray {
      width: 100px;
      height: 33px;
      background: #4d4d53;
      text-align: center;
      border-radius: 18.5px;
      cursor: pointer;
      z-index: 999;
    }
    .btn-active {
      background: linear-gradient(90deg, #ff7f5f 0%, #ff3776 100%);
    }
    .btn-bg {
      width: 130px;
      height: 33px;
      position: absolute;
      right: 32px;
      background: #2a2d3e;
      border-radius: 18.5px;
      padding-left: 22px;
    }
  }
}
