
.container {
    .title-hint {
        font-size: 18px;
    }
    .time {
        margin: 70px 0 19px;
        font-size: 34px;
        font-weight: 700;
    }
    .hint {
        margin-bottom: 60px;
        font-size: 16px;
    }
    .continue {
        width: 140px;
        height: 40px;
        color: #fff;
        background-color: #4b45ff;
        border-radius: 20px;
    }
}
